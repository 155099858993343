<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <ActionList
          :load-items-callback="getLocationVariationCombinations"
          :empty-text="$t('noCombinationsFound')"
        >
          <template v-slot:item="{ item, index }">
            <LocationVariationCombinationListItem
              :location-variation-combination="item"
              @select="selectVariationCombination"
            />
            <v-divider v-if="index < locationVariationCombinations.length - 1" />
          </template>
        </ActionList>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="showVariationCombinationDialog"
      max-width="550px"
      @click:outside="closeVariationCombinationDialog"
    >
      <EditLocationVariationCombination
        :combination="selectedVariationCombination"
        :location="location"
        @close="closeVariationCombinationDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
  import ActionList from "@/components/common/list/ActionList"
  import LocationVariationCombinationListItem from "@/components/location/LocationVariationCombinationListItem"
  import EditLocationVariationCombination from "@/components/location/EditLocationVariationCombination"

  export default {
    name: 'Combinations',
    components: {
      EditLocationVariationCombination,
      LocationVariationCombinationListItem,
      ActionList
    },
    data: () => ({
      showVariationCombinationDialog: false,
      selectedVariationCombination: null
    }),
    methods: {
      selectVariationCombination(value) {
        this.selectedVariationCombination = value
        this.showVariationCombinationDialog = true
      },
      closeVariationCombinationDialog() {
        this.$store.dispatch('getLocationVariationCombinations')
        this.selectedVariationCombination = null
        this.showVariationCombinationDialog = false
      },
      slicedLocationVariationCombinations(state) {
        if(state === 'active') {
          return this.locationVariationCombinations.filter(c => c.active === true)
        } else {
          return this.locationVariationCombinations.filter(c => c.active !== true)
        }
      },
      getLocationVariationCombinations() {
        return this.$store.dispatch('getLocationVariationCombinations').then(res => {
          if(this.selectedAppBarTab === 'inactive') {
            return res.filter(c => c.active !== true)
          }
          return res.filter(c => c.active === true)
        })
      }
    },
    computed: {
      location() {
        return this.$store.state.locations.find(location => { return location.uuid === this.$route.params.location_uuid })
      },
      zones() {
        return this.$store.state.zones
      },
      variations() {
        if(this.location === null) {
          return []
        } else {
          return this.location.variations
        }
      },
      locationVariationCombinations() {
        return this.$store.state.locationVariationCombinations.filter(c => { return c.location.uuid === this.$route.params.location_uuid })
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.slicedLocationVariationCombinations('active').length
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.slicedLocationVariationCombinations('inactive').length
            }
          }
        ]
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)

      if(this.tabs.length > 0) {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      }

      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: function() {
          this.selectVariationCombination(null)
        }.bind(this)
      })
    }
  }
</script>

<style scoped>

</style>
